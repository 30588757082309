import index from './module/index'
import store from './module/store'
import vip from './module/vip'
import room from './module/room'
import user from './module/user'
import setting from './module/setting'
import frameOut from './module/frameOut'

const frameIn = [
        index,
        store,
        vip,
        room,
        user,
        setting
    ]
    /**
     * 在主框架之外显示
     */

const frameOuts = frameOut


/**
 * 错误页面
 */

const errorPage = [{
            path: '/error/403',
            name: '403',
            meta: {
                title: '403'
            },
            component: () =>
                import ('@/views/system/error/403')
        },
        {
            path: '/error/500',
            name: '500',
            meta: {
                title: '500'
            },
            component: () =>
                import ('@/views/system/error/500')
        },
        {
            path: '*',
            name: '404',
            meta: {
                title: '404'
            },
            component: () =>
                import ('@/views/system/error/404')
        }
    ]
    // 重新组织后导出
export default [
    ...frameIn,
    ...frameOuts,
    ...errorPage
]