import BasicLayout from '@/components/layout/index'
const meta = {
    auth: true
}

const pre = 'store_'

export default {
    path: '/store',
    name: 'store',
    redirect: {
        name: `${pre}list`
    },
    meta,
    component: BasicLayout,
    children: [{
        path: 'list',
        name: `${pre}list`,
        meta: {
            auth: ['store-list'],
            title: '商户列表'
        },
        component: () =>
            import ('@/views/store/list')
    }]
}